// Import necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getAuth, OAuthProvider } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDrDhPr40cUM5w8zy7XISi8uSBW6uIcX9s",
  authDomain: "roster-pal.firebaseapp.com",
  projectId: "roster-pal",
  storageBucket: "roster-pal.appspot.com",
  messagingSenderId: "782259908491",
  appId: "1:782259908491:web:187a5bfad906a82f9a498e",
  measurementId: "G-SK18XR64LP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get instances of Firebase services
const auth = getAuth(app);
const functions = getFunctions(app);

// Microsoft Auth Provider
const microsoftProvider = new OAuthProvider("microsoft.com");

export { auth, microsoftProvider, functions };
