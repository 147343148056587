<template>
    <div>
        <button @click="signInWithMicrosoft">Sign in with Microsoft</button>
    </div>
</template>

<script>
import { auth, microsoftProvider, functions } from '../firebase.js';
import { signInWithPopup, signOut } from "firebase/auth";
import { httpsCallable } from 'firebase/functions';

export default {
    methods: {
        async signInWithMicrosoft() {
            try {
                const result = await signInWithPopup(auth, microsoftProvider);
                const user = result.user;
                console.log(user);
                if (user.email.includes('ifs.com')) {
                    console.log('IFS ACCOUNT');
                } else {
                    console.log('Not IFS');

                }
                // Get the user's ID token
                const token = await user.getIdToken();

                // Call cloud function to verify
                const verifyMicrosoftUser = httpsCallable(functions, 'verifyMicrosoftUser');
                const response = await verifyMicrosoftUser({ token });

                console.log('Verification result:', response.data);
            } catch (error) {
                console.error('Error during Microsoft login:', error);
            }
        },

    },

};

</script>