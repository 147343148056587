<template>
    <div class="container">
        <h1>Roster Pal - Team - 2025</h1><br><br>
        <form @submit.prevent="handleSubmit" class="form">
            <v-row>
                <v-col cols="12" md="4">
                    <label for="date">Select Date:</label>
                    <input type="date" v-model="date" required />
                </v-col>

                <v-col cols="12" md="4">
                    <label for="team">Select Team: ( * for all teams)</label>
                    <select v-model="team">
                        <option v-for="option in dropdownOptions" :key="option">
                            {{ option }}
                        </option>
                    </select>

                </v-col>

                <v-col cols="12" md="4">
                    <label for="shift">Select Shift:</label>
                    <select v-model="shift" required>
                        <option value="now">NOW</option>
                        <option value="m">Morning (7-4)</option>
                        <option value="d">Regular (8-5)</option>
                        <option value="e">EU (12.30-9.30)</option>
                        <option value="a">Afternoon (3-12)</option>
                        <option value="n">Night (11-8)</option>
                    </select>
                </v-col>

                <button type="submit" class="submit-button">Show the team</button>
            </v-row>
        </form>
        <center v-if="loader">
            <div class="loader"></div>
        </center>
        <div v-if="result && result.length" class="result">
            <h2>Response Data:</h2>
            <v-chip-group class="d-flex flex-column">
                <v-chip v-for="(value, key) in aboutData" :key="key"
                    :style="{ backgroundColor: '#fdbb05 ', color: 'white' }">
                    {{ value }}
                </v-chip>
            </v-chip-group>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Shift</th>
                        <th>Role</th>
                        <th>Area</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in result" :key="index"
                        :style="{ backgroundColor: item.role.startsWith('Shift') || item.role.includes('TL') || item.role.includes('Manager') ? '#A7C7E7' : 'white' }">
                        <td>{{ item.name }}</td>
                        <td>{{ item.shift }}</td>
                        <td>{{ item.role }}</td>
                        <td>{{ item.cap }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <modal-component :isVisible="showModal" :options="options" @close="showModal = false"
            @select="handleSelect"></modal-component>
        <v-snackbar v-model="snackbar" centered>
            Invalid Data

            <template v-slot:actions>
                <v-btn color="pink" variant="text" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="snackbar_noData" centered>
            No Data Found

            <template v-slot:actions>
                <v-btn color="red" variant="text" @click="snackbar_noData = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import ModalComponent from './ModalComponent.vue';
export default {
    components: {
        ModalComponent
    },
    data() {
        return {
            date: new Date().toISOString().split("T")[0],

            team: "Industry - energy",
            dropdownOptions: [],

            loader: false,

            shift: "now",
            result: null,
            showModal: false,
            aboutData: null,
            options: [],
            snackbar: false,
            snackbar_noData: false,
            url: "",
            shiftString: ''
        };
    },

    methods: {
        async handleSubmit() {
            this.loader = true
            this.shiftString = ''
            this.snackbar_noData = false;
            this.snackbar = false;
            this.result = null;
            const formattedDate = this.date.replace(/-/g, "/");
            const year = formattedDate.split('/')[0];
            if (year != 2025) {
                alert("RosterPal only has data of the year 2025");
                return
            }
            if (this.shift == 'now') {

                let now = new Date();
                let hours = now.getHours();
                // let hours = 15.31;
                let minutes = now.getMinutes();

                this.shiftString = ''; // Reset shiftString

                if (hours >= 7 && hours < 16) {
                    this.shiftString += 'm'; // Morning shift
                }
                if ((hours > 12 || (hours === 12 && minutes >= 30)) && (hours < 21 || (hours === 21 && minutes < 30))) {
                    this.shiftString += 'e'; // EU shift
                }
                if (hours >= 8 && hours < 17) {
                    this.shiftString += 'd'; // Day shift
                }
                if (hours >= 15 && hours < 24) {
                    this.shiftString += 'a'; // Afternoon shift
                }

                // Night shift handling:
                if (hours === 23) {
                    this.shiftString += 'n'; // Current day night shift
                } else if (hours < 8) {
                    this.shiftString += 'b'; // Previous day night shift
                }


                this.url = `https://rosterpalb.induwara.me/listCurrentMembers/${formattedDate}/${this.team}/${this.shiftString}`;
                // this.url = `http://127.0.0.1:5000/listCurrentMembers/${formattedDate}/${this.team}/${this.shiftString}`;
            } else {
                this.url = `https://rosterpalb.induwara.me/listMembers/${formattedDate}/${this.team}/${this.shift}`;
            }

            try {
                const response = await fetch(this.url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();

                if (data[0].Verticle) {
                    // console.log(data);
                    this.aboutData = data.slice(0).flat()[0]; // Assuming this returns the about object
                    // this.result = data.slice(1).flat();
                    var temp = data.slice(1).flat();
                    // console.log(temp);
                    this.result = temp

                    if (this.result.length < 1) {
                        this.snackbar_noData = true;
                    }

                    localStorage.setItem("teamCookie", this.team);
                    this.loader = false
                } else {
                    this.options = this.extractOptions(data); // Extract options based on your logic
                    this.showModal = true; // Show the modal
                    // Handle the case where the response does not match the expected format
                    console.log("Unexpected response format:", response);
                    this.loader = false
                }
            } catch (error) {
                this.snackbar = true
                console.error('Error fetching data:', error);
                this.loader = false
            }
        },
        async fetchDropdownOptions() {
            // Check if data exists in localStorage
            const storedOptions = localStorage.getItem("dropdownOptions");
            if (storedOptions) {
                this.dropdownOptions = JSON.parse(storedOptions);
            } else {
                this.loader = true

                try {
                    // Fetch from API if not found in localStorage
                    // const response = await fetch("http://127.0.0.1:5000/getAllTeams");
                    const response = await fetch("https://rosterpalb.induwara.me/getAllTeams");

                    if (!response.ok) {
                        console.log('Error while fetching teams:', response.statusText);
                        throw new Error("Failed to fetch");
                    }

                    const data = await response.json();

                    if (!data.teams || !Array.isArray(data.teams)) {
                        throw new Error("Invalid data format from API");
                    }

                    this.dropdownOptions = data.teams;
                    localStorage.setItem("dropdownOptions", JSON.stringify(data.teams)); // Save in local storage

                    // console.log("Dropdown options successfully loaded:", this.dropdownOptions);
                    this.loader = false

                } catch (error) {
                    alert('Try again after clearing browser cache')
                    console.error("Error fetching dropdown options:", error.message);
                    this.loader = false

                }


            }
        },
        shiftLabel(shift) {
            switch (shift) {
                case 'm':
                    return 'Morning';
                case 'd':
                    return 'Day';
                case 'e':
                    return 'Evening';
                case 'a':
                    return 'Afternoon';
                case 'n':
                    return 'Night';
                default:
                    return 'Unknown';
            }
        }, extractOptions(data) {
            // Logic to extract options from the response data
            return data.map(item => item); // Replace 'someProperty' with the relevant property
        },
        handleSelect(option) {
            this.team = option
            this.handleSubmit()
            // console.log("Selected option:", option);
            // Handle the selected option here
        }
    },
    mounted() {
        const savedName = localStorage.getItem("teamCookie");
        if (savedName) {
            this.team = savedName;
        }
        this.fetchDropdownOptions();
    }
};
</script>

<style scoped>
/* HTML: <div class="loader"></div> */
.loader {
    --s: 25px;
    --g: 65px;

    width: calc(3*(1.353*var(--s) + var(--g)));
    display: grid;
    justify-items: end;
    aspect-ratio: 3;
    overflow: hidden;
    --_m: linear-gradient(90deg, #0000, #000 15px calc(100% - 15px), #0000);
    -webkit-mask: var(--_m);
    mask: var(--_m);
}

.loader:before {
    content: "";
    width: 200%;
    background:
        linear-gradient(90deg, #ae49c7 50%, #0000 0),
        conic-gradient(from -90deg at var(--s) calc(0.353*var(--s)),
            #fff 135deg, #666 0 270deg, #aaa 0);
    background-blend-mode: multiply;
    --_m:
        linear-gradient(to bottom right,
            #0000 calc(0.25*var(--s)), #000 0 calc(100% - calc(0.25*var(--s)) - 1.414*var(--g)), #0000 0),
        conic-gradient(from -90deg at right var(--g) bottom var(--g), #000 90deg, #0000 0);
    -webkit-mask: var(--_m);
    mask: var(--_m);
    background-size: calc(100%/3) 100%, calc(100%/6) 100%;
    -webkit-mask-size: calc(100%/6) 100%;
    mask-size: calc(100%/6) 100%;
    -webkit-mask-composite: source-in;
    mask-composite: intersect;
    animation: l10 1s infinite linear;
}

@keyframes l10 {
    to {
        transform: translate(calc(100%/3))
    }
}



body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f9;
    color: #333;
}

.container {
    flex: 1;
    width: 85%;

    margin: 2rem auto;
    padding: 1.5rem;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

h1 {
    text-align: center;
    color: #6a1b9a;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 0.5rem;
    font-weight: bold;
}

input[type="date"],
input[type="text"],
select,
button {
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

button {
    background-color: #6a1b9a;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #5a1282;
}

.result {
    margin-top: 1.5rem;
    padding: 1.5rem;
    border: 1px solid #ddd;
    background-color: #fafafa;
    border-radius: 8px;
}

.result h2 {
    margin-top: 0;
    color: #6a1b9a;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
}

table,
th,
td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    text-align: left;
}

th {
    background-color: #f5f5f5;
}
</style>
